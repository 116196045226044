import React, { useState, useEffect, useRef } from 'react';
import { useFrogContext } from './LandingPage';
import {
    Container,
    Grid,
    Typography,
    useMediaQuery,
    Button,
    Box,
    Divider
} from '@mui/material';
import section3 from '../../assets/images/Section3.png';
import { useInView } from 'react-intersection-observer';
import { useTheme, ThemeProvider } from '@mui/material/styles';
function Section3() {
    const theme = useTheme();
    const xsMatched = useMediaQuery(theme.breakpoints.down('sx'));
    const smMatched = useMediaQuery(theme.breakpoints.down('sm'));
    const mdMatched = useMediaQuery(theme.breakpoints.down('md'));

    const { sec_3Viewed, setSec_3Viewed } = useFrogContext();
    // const myRef = useRef(null);
    const { ref: leftSideFrogRef, inView: leftSideFrog } = useInView();
    const { ref: marketingRef, inView: marketing } = useInView();
    const { ref: tokenomicsRef, inView: tokenomics } = useInView();

    useEffect(() => {
        setTimeout(() => {
            if (tokenomics) {
                setSec_3Viewed({
                    ...sec_3Viewed,
                    sec_3leftSideFrog: true,
                    sec_3marketing: true,
                    sec_3tokenomics: true
                });
            }
        }, [2000]);
    }, [leftSideFrog, marketing, tokenomics]);

    return (
        <Container
            sx={{
                // height: '100dvh',
                maxWidth: '95dvw',
                padding: '0px',
                margin: '0px'
            }}
        >
            <Grid container spacing={2}>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{ display: 'flex', justifyContent: 'center' }}
                    style={{
                        marginTop: smMatched ? '2rem' : ''
                    }}
                >
                    <img
                        ref={leftSideFrogRef}
                        className={`${
                            leftSideFrog &&
                            !sec_3Viewed?.sec_3leftSideFrog &&
                            !smMatched
                                ? 'aniSection3leftSideFrog'
                                : ''
                        }`}
                        src={section3}
                        style={{
                            paddingLeft: smMatched ? '6px' : '60px',
                            width: smMatched ? '280px' : '400px'
                            // marginTop: smMatched ? '-40px' : '-60px'
                        }}
                        alt=""
                    />
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <Box>
                        <Typography
                            ref={marketingRef}
                            className={`${
                                marketing &&
                                !sec_3Viewed?.sec_3marketing &&
                                !smMatched
                                    ? 'aniSection3marketing'
                                    : ''
                            }`}
                            style={{
                                // marginTop: smMatched
                                //     ? '16rem'
                                //     : mdMatched
                                //     ? '15rem'
                                //     : '',
                                width: smMatched ? '20rem' : '31rem',
                                color: '#1f2623',
                                fontSize: smMatched ? '20px' : '24px',
                                fontWeight: '400',
                                lineHeight: '45px',
                                textAlign: smMatched ? 'center' : 'center'
                            }}
                        >
                            The marketing mavens are the real secret sauce of
                            the Froggy Team. They could sell ice to a polar
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Typography
                        ref={tokenomicsRef}
                        className={`${
                            tokenomics &&
                            !sec_3Viewed?.sec_3tokenomics &&
                            !smMatched
                                ? 'aniSection3tokenomics'
                                : ''
                        }`}
                        style={{
                            color: '#0F3040',
                            fontSize: smMatched ? '35px' : '120px',
                            fontWeight: '400',
                            textAlign: smMatched ? 'center' : 'center',
                            padding: '0',
                            marginTop: '40px',
                            margin: '1px auto'
                        }}
                    >
                        FROGGY TOKENOMICS
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Section3;
