import React, { useRef, useEffect } from 'react';
import { useTheme, ThemeProvider } from '@mui/material/styles';
import { useFrogContext } from './LandingPage';
import { useInView } from 'react-intersection-observer';
import section5_1 from '../../assets/images/section5_1.png';
import section5_2 from '../../assets/images/section5_2.png';
import section5_3 from '../../assets/images/section5_3.png';
import section5_4 from '../../assets/images/section5_4.png';
import pancakeswap from '../../assets/images/pancakeswap-cake-logo 2.png';
import {
    Container,
    Grid,
    Button,
    CardActionArea,
    CardActions,
    CardMedia,
    CardContent,
    Box,
    Typography,
    Divider,
    Card,
    useMediaQuery
} from '@mui/material';

const btnStyle = {
    display: 'block',
    padding: '8px 30px',
    borderRadius: '4px',
    border: '1px 0px 0px 0px',
    opacity: '0px',
    background: '#387575',
    color: '#ffffff',
    letterSpacing: '1px',
    fontSize: '14px',
    fontWeight: 'regular',
    margin: '1px auto'
};
function Section5() {
    const theme = useTheme();
    const xsMatched = useMediaQuery(theme.breakpoints.down('sx'));
    const smMatched = useMediaQuery(theme.breakpoints.down('sm'));
    const mdMatched = useMediaQuery(theme.breakpoints.down('md'));

    const { sec5_viewed, setSec5_viewed } = useFrogContext();
    const { ref: howToBuyRef, inView: howToBuy } = useInView();
    const { ref: card1Ref, inView: card1 } = useInView();
    const { ref: card2Ref, inView: card2 } = useInView();
    const { ref: card3Ref, inView: card3 } = useInView();
    const { ref: card4Ref, inView: card4 } = useInView();

    // const smMatched = useMediaQuery('( max-width: 320px )');

    useEffect(() => {
        setTimeout(() => {
            if (card4) {
                setSec5_viewed({
                    ...sec5_viewed,
                    sec5_howToBuy: true,
                    sec5_card1: true,
                    sec5_card2: true,
                    sec5_card3: true,
                    sec5_card4: true
                });
            }
        }, [2000]);
    }, [howToBuy, card1, card2, card3, card4]);

    return (
        <Container
            sx={{
                // height: '100dvh',
                maxWidth: '95%',
                padding: '0px',
                margin: '0px',
                mt: 2
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <Typography
                            ref={howToBuyRef}
                            className={`${
                                howToBuy &&
                                !sec5_viewed?.sec5_howToBuy &&
                                !smMatched
                                    ? 'aniSection5howToBuy'
                                    : ''
                            }`}
                            style={{
                                marginTop: smMatched ? '1rem' : '1rem',
                                fontSize: smMatched ? '65px' : '90px',
                                color: '#9CF000',
                                marginBottom: '1rem'
                            }}
                        >
                            HOW TO BUY
                        </Typography>
                    </Box>
                </Grid>

                <Grid
                    container
                    spacing={1}
                    marginTop={smMatched ? '0.5rem' : '2rem'}
                    marginBottom="30px"
                >
                    <Grid
                        ref={card1Ref}
                        className={`${
                            card1 && !sec5_viewed?.sec5_card1 && !smMatched
                                ? 'aniSection5Card'
                                : ''
                        }`}
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        position="relative"
                        marginTop={
                            smMatched ? '50px' : mdMatched ? '0px' : '0px'
                        }
                    >
                        <Typography
                            style={{
                                fontFamily: 'Rubik',
                                fontSize: '80px',
                                color: '#B6E2EF',
                                position: 'absolute',
                                top: '-70px',
                                left: smMatched ? '80px' : '0px'
                            }}
                        >
                            1
                        </Typography>
                        <Card
                            sx={{
                                height: 340,
                                bgcolor: '#3389b4',
                                width: smMatched ? '280px' : '80%',
                                padding: '20px',
                                borderRadius: '20px',
                                zIndex: '200'
                            }}
                        >
                            <CardActionArea>
                                <CardMedia
                                    sx={{
                                        width: smMatched ? '80px' : '100px',
                                        margin: '1px auto',
                                        mb: 2
                                    }}
                                    component="img"
                                    image={section5_1}
                                    alt="fox face"
                                />
                                <CardContent sx={{ paddingX: 0 }}>
                                    <Typography
                                        color="#ffffff"
                                        sx={{
                                            width: smMatched
                                                ? '130px'
                                                : '130px',
                                            fontSize: smMatched
                                                ? '20px'
                                                : '20px'
                                        }}
                                        margin="1px auto"
                                        textAlign="center"
                                    >
                                        Download DEX Metamask 0r Trust Wallet. &
                                        Add Network - BNB
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        ref={card2Ref}
                        className={`${
                            card2 && !sec5_viewed?.sec5_card2 && !smMatched
                                ? 'aniSection5Card'
                                : ''
                        }`}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        position="relative"
                        marginTop={
                            smMatched ? '50px' : mdMatched ? '0px' : '0px'
                        }
                    >
                        <Typography
                            style={{
                                fontFamily: 'Rubik',
                                fontSize: '80px',
                                color: '#B6E2EF',
                                position: 'absolute',
                                top: '-70px',
                                left: smMatched ? '80px' : '0px'
                            }}
                        >
                            2
                        </Typography>

                        <Card
                            sx={{
                                height: 340,
                                bgcolor: '#3389b4',
                                width: smMatched ? '280px' : '80%',
                                padding: '20px',
                                borderRadius: '20px',
                                zIndex: '1'
                            }}
                        >
                            <CardActionArea>
                                <CardMedia
                                    sx={{
                                        width: smMatched ? '80px' : '100px',
                                        margin: '1px auto',
                                        mb: 2
                                    }}
                                    component="img"
                                    image={section5_2}
                                    alt="fox face"
                                />
                                <CardContent sx={{ paddingX: 0 }}>
                                    <Typography
                                        color="#ffffff"
                                        margin="1px auto"
                                        textAlign="center"
                                        sx={{
                                            width: smMatched
                                                ? '130px'
                                                : '130px',
                                            fontSize: smMatched
                                                ? '20px'
                                                : '20px'
                                        }}
                                    >
                                        Buy BNB on CEX ( Binance ) and Sent to
                                        your wallet.
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        ref={card3Ref}
                        className={`${
                            card3 && !sec5_viewed?.sec5_card3 && !smMatched
                                ? 'aniSection5Card'
                                : ''
                        }`}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        position="relative"
                        marginTop={
                            smMatched ? '50px' : mdMatched ? '50px' : '0px'
                        }
                    >
                        <Typography
                            style={{
                                fontFamily: 'Rubik',
                                fontSize: '80px',
                                color: '#B6E2EF',
                                position: 'absolute',
                                top: '-70px',
                                left: smMatched ? '80px' : '0px'
                            }}
                        >
                            3
                        </Typography>
                        <Card
                            sx={{
                                height: 340,
                                bgcolor: '#3389b4',
                                width: smMatched ? '280px' : '80%',
                                padding: '20px',
                                borderRadius: '20px',
                                zIndex: '1'
                            }}
                        >
                            <CardActionArea>
                                <CardMedia
                                    sx={{
                                        width: smMatched ? '80px' : '100px',
                                        margin: '1px auto',
                                        mb: 2
                                    }}
                                    component="img"
                                    image={pancakeswap}
                                    alt="fox face"
                                />
                                <CardContent sx={{ paddingX: 0 }}>
                                    <Box
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Typography
                                            color="#ffffff"
                                            margin="1px auto"
                                            textAlign="center"
                                            sx={{
                                                width: smMatched
                                                    ? '130px'
                                                    : '130px',
                                                fontSize: smMatched
                                                    ? '20px'
                                                    : '20px'
                                            }}
                                        >
                                            go to https:// pancakeswap.finance/
                                            Connect your Wallet
                                        </Typography>
                                    </Box>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        ref={card4Ref}
                        className={`${
                            card4 && !sec5_viewed?.sec5_card4 && !smMatched
                                ? 'aniSection5Card'
                                : ''
                        }`}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        position="relative"
                        marginTop={
                            smMatched ? '50px' : mdMatched ? '50px' : '0px'
                        }
                    >
                        <Typography
                            style={{
                                fontFamily: 'Rubik',
                                fontSize: '80px',
                                color: '#B6E2EF',
                                position: 'absolute',
                                top: '-70px',
                                left: smMatched ? '80px' : '0px'
                            }}
                        >
                            4
                        </Typography>
                        <Card
                            sx={{
                                height: 340,
                                bgcolor: '#3389b4',
                                width: smMatched ? '280px' : '80%',
                                padding: '20px',
                                borderRadius: '20px',
                                zIndex: '0'
                            }}
                        >
                            <CardActionArea>
                                <CardMedia
                                    sx={{
                                        width: smMatched ? '160px' : '200px',
                                        margin: '1px auto',
                                        mb: 2
                                    }}
                                    component="img"
                                    image={section5_4}
                                    alt="fox face"
                                />
                                <CardContent sx={{ paddingX: 0 }}>
                                    <Typography
                                        color="#ffffff"
                                        margin="1px auto"
                                        textAlign="center"
                                        marginBottom="15px"
                                        sx={{
                                            width: smMatched
                                                ? '130px'
                                                : '130px',
                                            fontSize: smMatched
                                                ? '20px'
                                                : '20px'
                                        }}
                                    >
                                        Swap BNB for $FROGGY
                                    </Typography>
                                    <Box
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                color: '#0C3039',
                                                fontSize: '10px',
                                                marginBottom: '10px'
                                            }}
                                        >
                                            0x06F1D2D640C93A0Ca3d11e31f0D732fCf7f28c65
                                        </Typography>
                                    </Box>
                                    <Button style={btnStyle}>CHART</Button>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Section5;
