import React, { useEffect } from 'react';
import { useFrogContext } from './LandingPage';
import { useTheme, ThemeProvider } from '@mui/material/styles';
import './commonCss.css';
import imgPhase1 from '../../assets/images/phase1.png';
import imgPhase2 from '../../assets/images/phase2.png';
import imgPhase3 from '../../assets/images/phase3.png';
import imgPhase4 from '../../assets/images/phase4.png';
import imgPhase5 from '../../assets/images/phase5.png';
import { useInView } from 'react-intersection-observer';

import {
    Container,
    Grid,
    Button,
    Box,
    CardActions,
    CardMedia,
    CardContent,
    Typography,
    Divider,
    Card,
    useMediaQuery
} from '@mui/material';
function RoadMap() {
    const theme = useTheme();
    const xsMatched = useMediaQuery(theme.breakpoints.down('sx'));
    const smMatched = useMediaQuery(theme.breakpoints.down('sm'));
    const mdMatched = useMediaQuery(theme.breakpoints.down('md'));

    const { roadmap_viewed, setRoadmap_viewed } = useFrogContext();
    const { ref: phase1Ref, inView: phase1 } = useInView();
    const { ref: phase2Ref, inView: phase2 } = useInView();
    const { ref: phase3Ref, inView: phase3 } = useInView();
    const { ref: phase4Ref, inView: phase4 } = useInView();
    const { ref: phase5Ref, inView: phase5 } = useInView();

    useEffect(() => {
        setTimeout(() => {
            if (phase5) {
                setRoadmap_viewed({
                    ...roadmap_viewed,
                    roadmap_phase1: true,
                    roadmap_phase2: true,
                    roadmap_phase3: true,
                    roadmap_phase4: true,
                    roadmap_phase5: true
                });
            }
        }, [2000]);
    }, [phase1, phase2, phase3, phase4, phase5]);

    return (
        <Container
            // style={{ marginTop: smMatched ? '220vh' : '10px' }}
            sx={{
                // minHeight: '100dvh',
                // width: '100dvw',
                padding: '0px',

                margin: '0px'
            }}
        >
            <Grid
                container
                spacing={2}
                sx={{
                    width: '100%',
                    mt: 5
                }}
            >
                <Grid item xs={12}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            textAlign: 'center'
                        }}
                    >
                        <Typography
                            style={{
                                fontSize: smMatched ? '70px' : '80px',
                                fontWeight: 'bold',
                                color: '#FFF',
                                letterSpacing: '2px'
                            }}
                        >
                            ROADMAP...
                        </Typography>
                    </Box>
                </Grid>

                <Grid container spacing={2} height={!smMatched && '70dvh'}>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={2.4}
                        style={{
                            display: 'flex',
                            flexDirection: smMatched ? 'column' : 'row',
                            justifyContent: smMatched ? 'center' : 'center',
                            alignItems: smMatched ? 'center' : 'flex-start'
                        }}
                    >
                        <img
                            src={imgPhase1}
                            ref={phase1Ref}
                            className={`${
                                phase1 &&
                                !roadmap_viewed?.roadmap_phase1 &&
                                !smMatched
                                    ? 'aniRoadmapPhase1'
                                    : ''
                            }`}
                            alt=""
                            style={{
                                width: smMatched ? '70%' : '70%'
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={2.4}
                        style={{
                            display: 'flex',
                            flexDirection: smMatched ? 'column' : 'row',
                            justifyContent: smMatched ? 'center' : 'center',
                            alignItems: smMatched ? 'center' : 'flex-end'
                        }}
                    >
                        <img
                            src={imgPhase2}
                            ref={phase2Ref}
                            className={`${
                                phase2 &&
                                !roadmap_viewed?.roadmap_phase2 &&
                                !smMatched
                                    ? 'aniRoadmapPhase1'
                                    : ''
                            }`}
                            alt=""
                            style={{
                                width: smMatched ? '70%' : '90%'
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={2.4}
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-start"
                    >
                        <img
                            src={imgPhase3}
                            ref={phase3Ref}
                            className={`${
                                phase2 &&
                                !roadmap_viewed?.roadmap_phase3 &&
                                !smMatched
                                    ? 'aniRoadmapPhase1'
                                    : ''
                            }`}
                            alt=""
                            style={{
                                display: 'flex',
                                maxWidth: '90%',
                                flexDirection: smMatched ? 'column' : 'row',
                                justifyContent: smMatched
                                    ? 'center'
                                    : 'flex-end',
                                alignItems: smMatched ? 'center' : 'flex-end'
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={2.4}
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-end"
                    >
                        <img
                            src={imgPhase4}
                            ref={phase4Ref}
                            className={`${
                                phase2 &&
                                !roadmap_viewed?.roadmap_phase4 &&
                                !smMatched
                                    ? 'aniRoadmapPhase1'
                                    : ''
                            }`}
                            alt=""
                            style={{
                                display: 'flex',
                                maxWidth: '90%',
                                flexDirection: smMatched ? 'column' : 'row',
                                justifyContent: smMatched
                                    ? 'center'
                                    : 'flex-end',
                                alignItems: smMatched ? 'center' : 'flex-end'
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={2.4}
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-start"
                    >
                        <img
                            src={imgPhase5}
                            ref={phase5Ref}
                            className={`${
                                phase2 &&
                                !roadmap_viewed?.roadmap_phase5 &&
                                !smMatched
                                    ? 'aniRoadmapPhase1'
                                    : ''
                            }`}
                            alt=""
                            style={{
                                display: 'flex',
                                maxWidth: '90%',
                                flexDirection: smMatched ? 'column' : 'row',
                                justifyContent: smMatched
                                    ? 'center'
                                    : 'flex-end',
                                alignItems: smMatched ? 'center' : 'flex-end'
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}

export default RoadMap;
