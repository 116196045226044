import React, {
    useState,
    createContext,
    useContext,
    useRef,
    useEffect,
    lazy,
    Suspense
} from 'react';
import { useTheme, ThemeProvider } from '@mui/material/styles';
import './commonCss.css';
import { useInView } from 'react-intersection-observer';
import logo from '../../assets/images/logo.png';
import background from '../../assets/images/background.png';
import {
    Container,
    Grid,
    Button,
    Box,
    styled,
    useMediaQuery,
    Typography
} from '@mui/material';

import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import Section4 from './Section4';
import Section5 from './Section5';
import Section6 from './Section6';
import RoadMap from './RoadMap';

const FrogContext = createContext();

function LandingPage() {
    const theme = useTheme();
    const isLargeScreen = useMediaQuery('( min-width: 1420px )');
    const isMediumScreen = useMediaQuery('( min-width: 1020px )');
    const [viewed, setViewed] = useState({
        sec1_mainFroggy: false,
        sec1_intro: false,
        sec1_btn1: false,
        sec1_btn2: false,
        sec1_btn3: false,
        sec1_btn4: false,
        sec1_btn5: false,
        sec1_lifeText: false
    });

    const [section2Viewed, setSection2Viewed] = useState({
        sec2_getReady: false,
        sec2_preSale: false,
        sec2_whoIsFroggy: false,
        sec2_behind: false,
        sec2_rightSideFrog: false
    });

    const [sec_3Viewed, setSec_3Viewed] = useState({
        sec_3leftSideFrog: false,
        sec_3marketing: false,
        sec_3tokenomics: false
    });

    const [sec4_viewed, setSec4_viewed] = useState({
        sec4_graph: false,
        sec4_availableOn: false,
        sec4_dedust: false
    });

    const [sec5_viewed, setSec5_viewed] = useState({
        sec5_howToBuy: false,
        sec5_card1: false,
        sec5_card2: false,
        sec5_card3: false,
        sec5_card4: false
    });

    const [sec6_viewed, setSec6_viewed] = useState({
        sec6_froggy: false,
        sec6_btn1: false,
        sec6_btn2: false,
        sec6_btn3: false,
        sec6_btn4: false,
        sec6_btn5: false,
        sec6_lastFroggy: false,
        sec6_lastText: false
    });

    const [roadmap_viewed, setRoadmap_viewed] = useState({
        roadmap_phase1: false,
        roadmap_phase2: false,
        roadmap_phase3: false,
        roadmap_phase4: false,
        roadmap_phase5: false
    });

    const [isVisible, setIsVisible] = useState(false);
    const myRef = useRef();
    const { ref, inView, entry } = useInView({
        threshold: 0
    });

    useEffect(() => {
        console.log('myRef', myRef.current);
        console.log('viewed', viewed);
    }, []);

    useEffect(() => {
        if (isVisible) {
            // Load the animation (if not already loaded)
        }
    }, [isVisible]);

    return (
        <Container>
            <FrogContext.Provider
                value={{
                    viewed,
                    setViewed,
                    section2Viewed,
                    setSection2Viewed,
                    sec_3Viewed,
                    setSec_3Viewed,
                    sec4_viewed,
                    setSec4_viewed,
                    sec5_viewed,
                    setSec5_viewed,
                    sec6_viewed,
                    setSec6_viewed,
                    roadmap_viewed,
                    setRoadmap_viewed
                }}
            >
                <Box
                    // maxWidth="false"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        position: 'absolute',
                        maxWidth: '95%',
                        padding: 0,
                        margin: 0
                    }}
                >
                    {/* Disable container width limit */}
                    <Box
                        spacing={0}
                        style={{
                            width: '90%',

                            display: 'flex',
                            justifyContent: 'space-between',
                            position: 'fixed',
                            top: '40px',
                            right: isLargeScreen
                                ? '120px'
                                : isMediumScreen
                                ? '80px'
                                : '40px'
                        }}
                    >
                        <img
                            src={logo}
                            style={{
                                height: '2rem',
                                marginLeft: '40px',
                                paddingBottom: '0',
                                marginBottom: '0'
                            }}
                        />
                        <Button
                            style={{
                                backgroundColor: '#3EC7D7',

                                color: '#452C28',
                                padding: '3px 27px',
                                letterSpacing: '2px',
                                borderRadius: '8px',
                                border: '1px solid #452C28',
                                opacity: ' 0px',
                                boxShadow: ' 0px 19px 9.8px 0px #00000040'
                            }}
                        >
                            BUY NOW
                        </Button>
                    </Box>

                    <Box>
                        <Grid container spacing={0} sx={{ p: 0, m: 0 }}>
                            <Grid item xs={12}>
                                <Section1 />
                            </Grid>
                            <Grid item xs={12}>
                                <Section2 />
                            </Grid>
                            <Grid item xs={12}>
                                <Section3 />
                            </Grid>
                            <Grid item xs={12}>
                                <Section4 />
                            </Grid>
                            <Grid item xs={12}>
                                <Section5 />
                            </Grid>
                            <Grid item xs={12}>
                                <RoadMap />
                            </Grid>
                            <Grid item xs={12}>
                                <Section6 />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </FrogContext.Provider>
        </Container>
    );
}

export const useFrogContext = () => {
    const frogContext = useContext(FrogContext);
    if (frogContext === undefined) {
        throw new Error('useFrogContext must be inside a FrogContext Provider');
    }
    return frogContext;
};

export default LandingPage;
