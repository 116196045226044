import React, { useState, useEffect } from 'react';
import frog1 from '../../assets/images/section1/frog1.png';
import { useFrogContext } from './LandingPage';
import { useTheme, ThemeProvider } from '@mui/material/styles';
import froggy from '../../assets/images/froggy.png';
import { useInView } from 'react-intersection-observer';
import {
    Container,
    Grid,
    Box,
    Typography,
    Button,
    useMediaQuery
} from '@mui/material';

function Section1() {
    const theme = useTheme();
    const xsMatched = useMediaQuery(theme.breakpoints.down('sx'));
    const smMatched = useMediaQuery(theme.breakpoints.down('sm'));
    const mdMatched = useMediaQuery(theme.breakpoints.down('md'));

    const { viewed, setViewed } = useFrogContext();
    const { ref: mainFroggyRef, inView: mainFroggy } = useInView();
    const { ref: introRef, inView: intro } = useInView();
    const { ref: btn1Ref, inView: btn1 } = useInView();
    const { ref: btn2Ref, inView: btn2 } = useInView();
    const { ref: btn3Ref, inView: btn3 } = useInView();
    const { ref: btn4Ref, inView: btn4 } = useInView();
    const { ref: btn5Ref, inView: btn5 } = useInView();
    const { ref: lifeTextRef, inView: lifeText } = useInView();

    // const isSmallScreen = useMediaQuery('( max-width: 320px )');

    useEffect(() => {
        setTimeout(() => {
            if (btn5) {
                setViewed({
                    ...viewed,
                    sec1_mainFroggy: true,
                    sec1_intro: true,
                    sec1_btn1: true,
                    sec1_btn2: true,
                    sec1_btn3: true,
                    sec1_btn4: true,
                    sec1_btn5: true,
                    sec1_lifeText: true
                });
            }
        }, [2000]);
    }, [mainFroggy, intro, btn1, btn2, btn3, btn4, btn5, lifeText]);

    const btnStyle = {
        padding: '8px 30px',
        borderRadius: '4px',
        border: '1px 0px 0px 0px',
        opacity: '0px',
        background: '#387575',
        color: '#ffffff',
        letterSpacing: '1px',
        fontSize: '16px',
        fontWeight: 'regular',
        margin: smMatched ? '10px 15px' : '5px 35px',
        minWidth: smMatched ? '110px' : ''
    };

    return (
        <Container
            sx={{
                // height: '100dvh',
                width: '100%',
                padding: '0',
                margin: '0'
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: smMatched ? 'center' : 'flex-end'
                        }}
                    >
                        <img
                            ref={mainFroggyRef}
                            className={`${
                                mainFroggy &&
                                !viewed?.sec1_mainFroggy &&
                                !smMatched
                                    ? 'aniFroggyScale'
                                    : ''
                            }`}
                            src={frog1}
                            style={{
                                height: '20rem'
                            }}
                        />
                    </Box>
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={6}
                    ref={introRef}
                    className={`${
                        intro && !viewed?.sec1_intro && !smMatched
                            ? 'aniSection1Intro'
                            : ''
                    }`}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: smMatched ? 'center' : 'flex-start'
                    }}
                >
                    <Box
                        width="100%"
                        style={{ textAlign: smMatched ? 'center' : 'left' }}
                    >
                        <Typography
                            style={{
                                color: ' #CFF6FD',
                                fontWeight: '400',
                                fontSize: '22px',
                                lineHeight: '37px'
                            }}
                        >
                            INTRODUCING
                        </Typography>

                        <img
                            src={froggy}
                            alt=""
                            style={{
                                width: smMatched ? '18rem' : '24rem'
                            }}
                        />

                        <Typography
                            style={{
                                color: '#1D3439',
                                fontSize: smMatched ? '30px' : '40px',
                                fontWeight: '400',
                                textAlign: smMatched ? 'center' : 'left'
                            }}
                        >
                            The Coin That's Hopping
                        </Typography>

                        <Typography
                            style={{
                                color: '#1D3439',
                                fontSize: smMatched ? '30px' : '42px',
                                fontWeight: '400',
                                textAlign: smMatched ? 'center' : 'left'
                            }}
                        >
                            it's Way to The Moon ..
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} align="center">
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <Typography
                            ref={lifeTextRef}
                            className={`${
                                lifeText && !viewed?.sec1_lifeText && !smMatched
                                    ? 'aniSection1LifeText'
                                    : ''
                            }`}
                            style={{
                                width: smMatched ? '18rem' : '32rem',
                                color: '#D0F5FB',
                                fontSize: '19px',
                                fontWeight: '400',
                                letterSpacing: '0.11em',
                                textAlign: 'center'
                            }}
                        >
                            Life's too short to stay in the tadpole stage; it's
                            time to grow some legs and hop forward.
                        </Typography>
                    </Box>
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={12}
                    // align="center"
                    display="flex"
                    flexWrap="wrap"
                    justifyContent="center"
                >
                    <Button
                        ref={btn1Ref}
                        style={btnStyle}
                        className={`${
                            btn1 && !viewed?.sec1_btn1 && !smMatched
                                ? 'aniSection6Btn1'
                                : ''
                        }`}
                        onClick={() => {
                            window.open('https://t.me/froggymeme', '_blank');
                        }}
                    >
                        TELEGRAM
                    </Button>
                    <Button
                        ref={btn2Ref}
                        style={btnStyle}
                        className={`${
                            btn2 && !viewed?.sec1_btn2 && !smMatched
                                ? 'aniSection6Btn2'
                                : ''
                        }`}
                        onClick={() => {
                            window.open(
                                'https://twitter.com/froggymeme77',
                                '_blank'
                            );
                        }}
                    >
                        TWITTER
                    </Button>
                    <Button
                        ref={btn3Ref}
                        style={btnStyle}
                        className={`${
                            btn3 && !viewed?.sec1_btn3 && !smMatched
                                ? 'aniSection6Btn3'
                                : ''
                        }`}
                    >
                        AIRDROP
                    </Button>
                    <Button
                        ref={btn4Ref}
                        style={btnStyle}
                        className={`${
                            btn4 && !viewed?.sec1_btn4 && !smMatched
                                ? 'aniSection6Btn4'
                                : ''
                        }`}
                    >
                        FAIRLAUNCH
                    </Button>
                    <Button
                        ref={btn5Ref}
                        style={btnStyle}
                        className={`${
                            btn5 && !viewed?.sec1_btn5 && !smMatched
                                ? 'aniSection6Btn5'
                                : ''
                        }`}
                    >
                        CONTRACT ADDRESS
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Section1;
