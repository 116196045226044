import LandingPage from './pages/landingPage/LandingPage';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import BackgroundLayout from './BackgroundLayout';
const theme = createTheme({
    typography: {
        fontFamily: 'LondrinaSolid, Arial, sans-serif' // Replace YourCustomFont with your font family
    }
    // breakpoints: {
    //     values: {
    //         xs: 320, // Extra small: zero or more pixels
    //         sm: 480, // Small: screens larger than 600px
    //         md: 640, // Medium: screens larger than 900px
    //         lg: 768, // Large: screens larger than 1200px
    //         xl: 1080 // Extra large: screens larger than 1536px
    //     }
    // }
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <BackgroundLayout>
                <LandingPage />
            </BackgroundLayout>
        </ThemeProvider>
    );
}

export default App;
