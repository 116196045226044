import React, { useRef, useEffect, useState } from 'react';
import { useFrogContext } from './LandingPage';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { useTheme, ThemeProvider } from '@mui/material/styles';
import {
    Container,
    Grid,
    Typography,
    Button,
    useMediaQuery,
    Box,
    Divider
} from '@mui/material';
import { ChartsXAxis } from '@mui/x-charts/ChartsXAxis';
import cube from '../../assets/images/cube.png';
import pancakeswap from '../../assets/images/pancakeswap-cake-logo 2.png';
import { useInView } from 'react-intersection-observer';
function Section4() {
    const theme = useTheme();
    const xsMatched = useMediaQuery(theme.breakpoints.down('sx'));
    const smMatched = useMediaQuery(theme.breakpoints.down('sm'));
    const mdMatched = useMediaQuery(theme.breakpoints.down('md'));

    const { sec4_viewed, setSec4_viewed } = useFrogContext();
    const { ref: graphRef, inView: graph } = useInView();
    const { ref: availableOnRef, inView: availableOn } = useInView();
    const { ref: dedustRef, inView: dedust } = useInView();

    useEffect(() => {
        setTimeout(() => {
            if (dedust) {
                setSec4_viewed({
                    ...sec4_viewed,
                    sec4_graph: true,
                    sec4_availableOn: true,
                    sec4_dedust: true
                });
            }
        }, [2000]);
    }, [graph, availableOn, dedust]);

    const data = [
        { label: '35%', value: 35, color: '#1B677C' },
        { label: '20%', value: 15, color: '#57A6BC' },
        { label: '20%', value: 15, color: '#5FC6E9' },
        { label: '10%', value: 10, color: '#426373' },
        { label: '10%', value: 10, color: '#3389B4' },
        { label: '10%', value: 10, color: '#0F3040' },
        { label: '5%', value: 5, color: '#61C5E9' }
    ];

    var options = {
        segmentShowStroke: false
    };

    const sizing = {
        width: smMatched ? 300 : 400,
        height: smMatched ? 300 : 400,
        legend: { hidden: true },
        stroke: false
        // marginRight: 5,
        // width: smMatched && 300,
        // height: smMatched && 300,
        // legend: { hidden: true },
        // stroke: false
    };
    const TOTAL = data.map(item => item.value).reduce((a, b) => a + b, 0);

    const getArcLabel = params => {
        const percent = params.value / TOTAL;
        return `${(percent * 100).toFixed(0)}%`;
    };

    return (
        <Container
            sx={{
                // height: '100dvh',
                maxWidth: '99%',
                padding: '0px',
                margin: '0px'
            }}
        >
            <Grid
                ref={graphRef}
                className={`${
                    graph && !sec4_viewed?.sec4_graph && !smMatched
                        ? 'aniSection4graph'
                        : ''
                }`}
                container
                spacing={1}
                style={{
                    borderRadius: '48px',
                    maxWidth: '100%',
                    backgroundImage: `linear-gradient(to bottom, #1B89A8, #1B89A8)`
                }}
            >
                <Grid item xs={12} sm={6}>
                    <Box
                        style={{
                            // marginTop: '20px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <PieChart
                            series={[
                                {
                                    outerRadius: smMatched ? 100 : 150,
                                    data,
                                    arcLabel: getArcLabel
                                }
                            ]}
                            sx={{
                                [`& .${pieArcLabelClasses.root}`]: {
                                    fill: 'white',
                                    fontSize: 14
                                }
                            }}
                            {...sizing}
                        />
                    </Box>
                    <Typography
                        style={{
                            color: '#ffffff',
                            fontSize: smMatched ? '15px' : '20px',
                            fontWeight: 'regular',
                            textAlign: 'center',
                            marginTop: '-30px'
                        }}
                    >
                        TOTAL SUPPLY : 177,000,000,000,000
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid
                        container
                        spacing={2}
                        padding="10px 20px 10px 5px"
                        // height="100%"
                    >
                        <Grid
                            item
                            xs={12}
                            // style={{
                            //     height: '100%',
                            //     display: 'flex',
                            //     flexDirection: 'column',
                            //     justifyContent: 'space-around'
                            // }}
                        >
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    borderLeft: '4px solid #FFFFFF',
                                    margin: '10px 0px'
                                }}
                            >
                                <Typography
                                    style={{
                                        fontSize: '22px',
                                        color: '#5FC6E9',
                                        paddingLeft: '20px'
                                    }}
                                >
                                    PRESALE
                                </Typography>
                                <Typography style={{ fontSize: '22px' }}>
                                    35%
                                </Typography>
                            </Box>

                            <Divider sx={{ width: '100%' }} color="#87c3d6" />

                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    borderLeft: '4px solid #FFFFFF',
                                    margin: '10px 0px'
                                }}
                            >
                                <Typography
                                    style={{
                                        fontSize: '22px',
                                        color: '#5FC6E9',
                                        paddingLeft: '20px'
                                    }}
                                >
                                    LP
                                </Typography>
                                <Typography style={{ fontSize: '22px' }}>
                                    10%
                                </Typography>
                            </Box>
                            <Divider sx={{ width: '100%' }} color="#87c3d6" />
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    borderLeft: '4px solid #FFFFFF',
                                    margin: '10px 0px'
                                }}
                            >
                                <Typography
                                    style={{
                                        fontSize: '22px',
                                        color: '#5FC6E9',
                                        paddingLeft: '20px'
                                    }}
                                >
                                    MARKETING
                                </Typography>
                                <Typography style={{ fontSize: '22px' }}>
                                    15%
                                </Typography>
                            </Box>
                            <Divider sx={{ width: '100%' }} color="#87c3d6" />
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    borderLeft: '4px solid #FFFFFF',
                                    margin: '10px 0px'
                                }}
                            >
                                <Typography
                                    style={{
                                        fontSize: '22px',
                                        color: '#5FC6E9',
                                        paddingLeft: '20px'
                                    }}
                                >
                                    TEAM
                                </Typography>
                                <Typography style={{ fontSize: '22px' }}>
                                    15%
                                </Typography>
                            </Box>
                            <Divider sx={{ width: '100%' }} color="#87c3d6" />
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    borderLeft: '4px solid #FFFFFF',
                                    margin: '10px 0px'
                                }}
                            >
                                <Typography
                                    style={{
                                        fontSize: '22px',
                                        color: '#5FC6E9',
                                        paddingLeft: '20px'
                                    }}
                                >
                                    CEX
                                </Typography>
                                <Typography style={{ fontSize: '22px' }}>
                                    10%
                                </Typography>
                            </Box>
                            <Divider sx={{ width: '100%' }} color="#87c3d6" />
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    borderLeft: '4px solid #FFFFFF',
                                    margin: '10px 0px'
                                }}
                            >
                                <Typography
                                    style={{
                                        fontSize: '22px',
                                        color: '#5FC6E9',
                                        paddingLeft: '20px'
                                    }}
                                >
                                    STAKING
                                </Typography>
                                <Typography style={{ fontSize: '22px' }}>
                                    10%
                                </Typography>
                            </Box>
                            <Divider sx={{ width: '100%' }} color="#87c3d6" />
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    borderLeft: '4px solid #FFFFFF',
                                    margin: '10px 0px'
                                }}
                            >
                                <Typography
                                    style={{
                                        fontSize: '22px',
                                        color: '#5FC6E9',
                                        paddingLeft: '20px'
                                    }}
                                >
                                    AIRDROP
                                </Typography>
                                <Typography style={{ fontSize: '22px' }}>
                                    5%
                                </Typography>
                            </Box>
                            <Typography
                                style={{
                                    color: '#ffffff',
                                    fontSize: smMatched ? '15px' : '18px',
                                    fontWeight: 'regular',
                                    textAlign: 'center'
                                }}
                            >
                                50% Reserved Supply Locked in Contract for
                                Future AI Products
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} md={6}>
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: smMatched ? 'center' : 'flex-end',
                            alignItems: 'center'
                        }}
                    >
                        <Typography
                            ref={availableOnRef}
                            className={`${
                                availableOn &&
                                !sec4_viewed?.sec4_availableOn &&
                                !smMatched
                                    ? 'aniSection4avelableOn'
                                    : ''
                            }`}
                            style={{
                                fontSize: smMatched ? '30px' : '75px',
                                color: '#FFF'
                            }}
                        >
                            AVAILABLE ON:
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box
                        ref={dedustRef}
                        className={`${
                            dedust && !sec4_viewed?.sec4_dedust && !smMatched
                                ? 'aniSection4dedust'
                                : ''
                        }`}
                        style={{
                            display: 'flex',
                            backgroundColor: '#2E5761',
                            width: smMatched ? '60%' : '70%',
                            justifyContent: smMatched ? 'center' : 'center',

                            alignItems: 'center',
                            // padding: mdMatched ? '5px 20px' : '5px 10px',
                            borderRadius: '40px',
                            margin: '2px auto',
                            marginTop: smMatched ? '10px' : '10px'
                        }}
                    >
                        <img
                            src={pancakeswap}
                            height="50px"
                            marginRight="20px"
                            alt=""
                        />
                        <Typography
                            style={{
                                fontSize: smMatched ? '25px' : '50px',
                                color: '#FFF',
                                marginLeft: '20px'
                            }}
                        >
                            PANCAKESWAP
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Section4;
