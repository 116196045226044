import React, { useState, useEffect, useRef } from 'react';
import { useFrogContext } from './LandingPage';
import { useTheme, ThemeProvider } from '@mui/material/styles';
import { useInView } from 'react-intersection-observer';
import {
    Box,
    Grid,
    Typography,
    Container,
    Button,
    Divider,
    useMediaQuery
} from '@mui/material';
import section2 from '../../assets/images/section2.png';

function Section2({ ref }) {
    const theme = useTheme();
    const xsMatched = useMediaQuery(theme.breakpoints.down('sx'));
    const smMatched = useMediaQuery(theme.breakpoints.down('sm'));
    const mdMatched = useMediaQuery(theme.breakpoints.down('md'));

    const { section2Viewed, setSection2Viewed } = useFrogContext();

    const { ref: myRef, inView: getReady } = useInView();
    const { ref: preSaleRef, inView: preSale } = useInView();
    const { ref: whoIsFroggyRef, inView: whoIsFroggy } = useInView();
    const { ref: behindRef, inView: behind } = useInView();
    const { ref: rightSideFrogRef, inView: rightSideFrog } = useInView();

    useEffect(() => {
        setTimeout(() => {
            if (rightSideFrog) {
                setSection2Viewed({
                    ...section2Viewed,
                    sec2_getReady: true,
                    sec2_preSale: true,
                    sec2_whoIsFroggy: true,
                    sec2_behind: true,
                    sec2_rightSideFrog: true
                });
            }
        }, [2000]);
    }, [getReady, preSale, whoIsFroggy, behind, rightSideFrog]);

    return (
        <Container
            sx={{
                // border: '10px solid black',
                // height: '100dvh',
                width: '95dvw',
                padding: '0px',
                margin: '0px',
                mt: 5
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <Typography
                            className={`${
                                getReady &&
                                !section2Viewed?.sec2_getReady &&
                                !smMatched
                                    ? 'aniSection2GetReady'
                                    : ''
                            }`}
                            ref={myRef}
                            style={{
                                textAlign: 'center',
                                width: mdMatched ? '18rem' : '40rem',
                                color: '#1A373C',
                                fontSize: smMatched ? '18px' : '24px',
                                fontWeight: '400',
                                letterSpacing: '0.11em'
                            }}
                        >
                            Get ready to jump into the craziest meme adventure
                            of your life by hopping on board the Froggy express!
                        </Typography>
                    </Box>
                </Grid>

                <Grid
                    item
                    xs={12}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <Button
                        ref={preSaleRef}
                        className={`${
                            preSale &&
                            !section2Viewed?.sec2_preSale &&
                            !smMatched
                                ? 'aniSection2preSale'
                                : ''
                        }`}
                        style={{
                            fontSize: smMatched ? '22px' : '28px',
                            backgroundColor: '#3EC7D7',
                            color: '#452C28',
                            width: '15rem',
                            height: '3rem',
                            letterSpacing: '2px',
                            borderRadius: '40px',
                            // border: '1px solid #452C28',
                            opacity: ' 0px',
                            boxShadow: ' 0px 19px 9.8px 0px #00000040',
                            zIndex: '-1'
                        }}
                    >
                        PRESALE
                    </Button>
                </Grid>

                <Grid
                    container
                    spacing={2}
                    style={{
                        marginTop: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <Grid item xs={12} md={3}>
                        <Typography
                            style={{
                                display: 'flex',
                                justifyContent: smMatched
                                    ? 'center'
                                    : 'flex-end',
                                fontSize: '1.25rem',
                                color: '#ffffff'
                                // margin: '1px auto'
                            }}
                        >
                            FROGGY
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <Divider
                            sx={{
                                // width: '10rem',
                                bgcolor: '#ffffff',
                                height: '2px',
                                width: smMatched ? '12rem' : '20rem',
                                margin: '1px auto'
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                fontSize: '1.25rem',
                                color: '#1D3439'
                                // margin: '1px auto'
                            }}
                        >
                            MAGICIAN OF CRYPTO
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Typography
                        ref={whoIsFroggyRef}
                        className={`${
                            whoIsFroggy &&
                            !section2Viewed?.sec2_whoIsFroggy &&
                            !smMatched
                                ? 'aniSection2whoIsFroggy'
                                : ''
                        }`}
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            fontSize: '30px',
                            margin: '1px auto',
                            color: '#BAE5F2',

                            textShadow:
                                '-2px 0 #2E2854, 0 2px #2E2854, 2px 0 #2E2854, 0 -2px #2E2854'
                        }}
                    >
                        WHO’S FROGGY ?!
                    </Typography>
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={6}
                    style={{
                        display: 'flex',
                        // justifyContent: smMatched ? 'center' : 'space-between'
                        justifyContent: 'center'
                    }}
                >
                    <Typography
                        ref={behindRef}
                        className={`${
                            behind && !section2Viewed?.sec2_behind && !smMatched
                                ? 'aniSection2behind'
                                : ''
                        }`}
                        style={{
                            // marginTop: smMatched ? '' : '',
                            // width: smMatched ? '18rem' : '31rem',
                            maxWidth: '90%',
                            color: '#26221F',
                            fontSize: smMatched ? '20px' : '24px',
                            fontWeight: '400',
                            lineHeight: '45px',
                            textAlign: 'center'
                        }}
                        // sx={{ ml: smMatched ? 1 : 10, mt: 1, mb: 1 }}
                    >
                        Behind every hop the Froggy takes, there's an advanced
                        technical team and a dedicated marketing community
                        propelling it forward
                    </Typography>
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={6}
                    // align={smMatched ? 'center' : 'left'}
                    style={{
                        display: 'flex',
                        // justifyContent: smMatched ? 'center' : 'space-between'
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <img
                        ref={rightSideFrogRef}
                        className={`${
                            rightSideFrog &&
                            !section2Viewed?.sec2_rightSideFrog &&
                            !smMatched
                                ? 'aniSection2rightSideFrog'
                                : ''
                        }`}
                        src={section2}
                        style={{
                            width: '300px',
                            zIndex: -1
                            // marginRight: smMatched ? '16px' : '200px',
                            // marginTop: smMatched ? '-30px' : '-65px'
                        }}
                        alt=""
                    />
                </Grid>
            </Grid>
        </Container>
    );
}

export default Section2;
