import React from 'react';
import { Box } from '@mui/material';
import styled from '@emotion/styled';
import background from './assets/images/background.png';

const StyledBackground = styled(Box)`
    position: fixed;
    top: 0;
    left: 0;
    width: 100dvw;
    height: 100dvh;
    background-image: url(${background});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: -2;
`;

const ContentWrapper = styled(Box)`
    position: relative;
    padding: 20px;
    overflow-y: auto;
    height: 100vh;
`;

const WindowContent = styled(Box)`
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    /* padding: 20px; */
    max-width: 95dvw;
    margin: 0 auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    max-height: 100dvh;
    z-index: 1;
`;

const BackgroundLayout = ({ children }) => {
    return (
        <>
            <StyledBackground />
            <ContentWrapper>
                <WindowContent>{children}</WindowContent>
            </ContentWrapper>
        </>
    );
};

export default BackgroundLayout;
